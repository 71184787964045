<template>
  <Loader :value="promise" style="height: 100%">
    <div
      v-if="coordinates && coordinates.length"
      class="kpb-project-map"
      :style="`aspect-ratio: ${aspectRatio}`"
    >
      <div ref="map" class="kpb-project-map__mapbox" style="height: 100%; width: 100%"></div>
    </div>
  </Loader>
</template>

<script>
import Vue, { ref } from 'vue'
import { mapState, mapActions } from 'vuex'
import { Map, Marker, NavigationControl, Popup } from 'mapbox-gl'
import LazyLoad from 'vue-make-lazy'
import MapCard from './MapCard'
import { Slugify } from '@/utils'

/** Map component */
export default {
  setup() {
    const map = ref()
    const promise = ref(null)

    return {
      map,
      promise,
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    aspectRatio: {
      type: String,
      default: '2/1',
    },
    mapOptions: {
      type: Object,
      default: () => ({}),
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    markerOptions: {
      type: Object,
      default: () => ({
        color: 'var(--primary)',
      }),
    },
    displayAddress: {
      type: Boolean,
      default: false,
    },
    fetchProjects: {
      type: Boolean,
      default: true,
    },
    customMarkerURL: {
      type: String,
    },
    excluded: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 'data.projects.length': {
    //   handler: 'initMap',
    // },
    // 'data.markers': {
    //   handler: 'initMarkers',
    // },
    // isVisible: {
    //   handler(newIsVisible) {
    //     if (newIsVisible) {
    //       // this.initMap()
    //     } else {
    //       this.removeMap()
    //     }
    //   },
    //   immediate: true,
    // },
  },
  data() {
    return {
      map: null,
      markers: [],
      initialized: false,
    }
  },
  computed: {
    coordinates() {
      return this.$path('data.location.coordinates') || this.$path('data.coordinates')
    },
    defaultOptionsComp() {
      return {
        style: process.env.VUE_APP_MAPBOX_STYLE,
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        zoom: 12,
        dragPan: true,
        scrollZoom: false,
        dragRotate: false,
        cooperativeGestures: true,
        projection: 'mercator',
        ...this.mapOptions,
      }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
    initMap() {
      if (this.initialized) return
      if (!this.coordinates) return

      const bounds =
        this.$path('data.projects.length') > 1
          ? {
              bounds: this.fitBoundsToMarkers(),
              fitBoundsOptions: { padding: 75 },
            }
          : {
              center:
                this.$path('data.projects.0.address.location.coordinates') || this.coordinates,
            }

      this.map = new Map({
        container: this.$refs.map,
        center: this.coordinates ? [...this.coordinates] : [0, 0],
        ...this.defaultOptionsComp,
        ...bounds,
      })

      this.initMarkers()
      this.initControls()

      this.initialized = true
    },
    initMarkers() {
      this.markers.forEach(m => m.remove())

      if (this.$path('data.projects.length') && this.fetchProjects) {
        const mapCard = Vue.extend(MapCard)

        let markers = this.$path('data.projects')

        markers.forEach(marker => {
          var mapCardPopup = new mapCard({
            parent: this,
            propsData: {
              url: `#${Slugify(marker.name)}`,
              imageUrl: this.getImage(marker.media?.cover || marker.customFields?.cover),
              name: marker.name,
            },
          }).$mount()

          const popup = new Popup({ offset: 25, closeButton: false }).setDOMContent(
            mapCardPopup.$el,
          )

          let createMarker = { ...this.markerOptions }

          if (this.customMarkerURL) {
            const el = document.createElement('div')
            el.className = 'marker'
            el.style.backgroundImage = `url(${this.customMarkerURL})`

            createMarker = { element: el, ...this.markerOptions }
          }

          if (marker.address?.location) {
            this.markers.push(
              new Marker(createMarker)
                .setLngLat(marker.address.location.coordinates)
                .setPopup(popup)
                .addTo(this.map),
            )
          }
        })
      } else {
        let markers = this.data.markers || [this.coordinates]

        markers.forEach(marker => {
          this.markers.push(
            new Marker({
              ...this.markerOptions,
            })
              .setLngLat([...marker])
              .addTo(this.map),
          )
        })
      }
    },
    initControls() {
      if (!this.showControls) return
      const controls = [new NavigationControl()]
      controls.forEach(c => this.map.addControl(c, 'bottom-left'))
    },
    fitBoundsToMarkers() {
      return this.getMinMaxCords()
    },
    getMinMaxCords() {
      let coordinates = this.$path('data.projects')
        .map(project => {
          if (!project.address?.location) return null
          return project.address.location
        })
        .filter(Boolean)

      var lat = coordinates.map(p => p.coordinates[0])
      var lng = coordinates.map(p => p.coordinates[1])

      var min_coords = [Math.min.apply(null, lat), Math.min.apply(null, lng)]
      var max_coords = [Math.max.apply(null, lat), Math.max.apply(null, lng)]

      return [min_coords, max_coords]
    },
    getImage(value) {
      if (!value || !value.length) return
      let result = value.find(i => {
        if (i.type) return i.type.startsWith('image')
        return i
      })
      if (!result) return
      return result.url
    },
    removeMap() {
      if (this.map && 'remove' in this.map) {
        this.map.remove()
        this.map = null
      }
      this.initialized = false
    },
  },
  beforeDestroy() {
    this.removeMap()
  },

  mounted() {
    this.promise = this.fetch().then(() => this.initMap())
  },

  components: {
    LazyLoad,
    MapCard,
  },
}
</script>

<style lang="scss">
.kpb-project-map {
  background-color: #f5f5f5;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #aeb2b6;
  height: 100%;

  &__address {
    text-align: center;
    background: var(--primary);
    border: none;
    color: var(--primary-contrast);
    font-size: 1.25em;
    padding: 0.5rem;

    @include respond-below('phone') {
      font-size: 1em;
    }
  }

  &__mapbox {
    max-width: 100%;
    width: 100%;
    min-height: 450px;
  }
}

.marker {
  width: 27px;
  height: 41px;
  background-size: contain;
  background-repeat: no-repeat;
}

.mapboxgl {
  &-ctrl {
    &-attrib,
    &-logo {
      display: none;
    }
  }

  &-marker {
    cursor: pointer;
  }

  &-marker circle {
    fill: var(--primary-contrast);
  }

  &-touch-pan-blocker {
    display: none;
  }

  &-popup {
    &-close {
      &-button {
        font-size: 2em;
      }
    }

    &-content {
      padding: 0 !important;
    }
  }
}
</style>
